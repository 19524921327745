<template>
  <div v-if="item.enable" class="scroll-left">
    <p v-html="item.body.html"></p>
  </div>
</template>
<script>
export default {
  name: "slider-message",
  components: {},
  props: {
  },
  mounted() {
  },
  inject: ["providedState"],
  computed: {
    item(){
      if(this.items[0]){
        return this.items[0];
      }
      return {};
    },
    items(){
      return this.providedState.contents["SlideMessage2"];
    }
  },
  methods: {
  },
  data() {
    return {
    };
  }
};
</script>
<style scoped>
.scroll-left {
 height: 50px;	
 overflow: hidden;
 position: relative;
 color: black;
}
.scroll-left p {
 position: absolute;
 width: 100%;
 width: max-content;
 height: 100%;
 margin: 0;
 line-height: 40px;
 font-size:18px;
 text-align: center;
 /* Starting position */
 transform:translateX(100%);
 /* Apply animation to this element */
 animation: scroll-left 15s linear infinite;
}
.scroll-left p:hover{
  animation-play-state: paused;
}
/* Move it (define the animation) */
@keyframes scroll-left {
 0%   {
 transform: translateX(100%); 		
 }
 100% {
 transform: translateX(-100%); 
 }
}
</style>