<template>
  <div class="box" :class="classStyle" style="position:relative">
    <fixed-notice msg="更新情報を反映するには「公開予約」ボタンを押してください。" :is-show="notice_show" />
    <div class="columns is-gapless">
      <div class="column">
        <figure class="image">
          <img v-if="src" :src="src" :alt="title" style="width:auto" />
          <span v-else>画像を選択してください。</span>
        </figure>
      </div>
      <div class="column">
        <div class="field is-horizontal">
          <div class="field-label is-small">
            <label class="label">タイトル</label>
          </div>
          <div class="field-body is-small">
            <div class="field">
              <p class="control is-expanded">
                <input
                  class="input"
                  placeholder="タイトル"
                  v-model="title"
                  :disabled="!isEdit"
                  @change="changeTitle($event)"
                />
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-small">
            <label class="label">並び順</label>
          </div>
          <div class="field-body is-small">
            <div class="field">
              <p class="control is-expanded">
                <input
                  class="input"
                  type="number"
                  placeholder="並び順"
                  v-model.number="sort"
                  :disabled="!isEdit"
                  @change="changeSort($event)"
                />
              </p>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-body is-small">
            <div class="file" v-if="isEdit">
              <label class="file-label">
                <input class="file-input" type="file" accept="image/*" @change="changeSrc($event)" />
                <span class="file-cta">
                  <span class="file-icon">
                    <i class="fas fa-upload"></i>
                  </span>
                  <span class="file-label">画像を選択</span>
                </span>
              </label>
            </div>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <div class="select is-small">
                  <select @change="selectDisplay" v-model="display" :disabled="!isEdit">
                    <option value>表示</option>
                    <option value="none">非表示</option>
                    <option value="delete">削除</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.is-gray {
  background-color: gray;
}
</style>
<script>
import fixedNotice from "./fixed-notice.vue";

export default {
  name: "slider",
  components: { fixedNotice },
  props: {
    item: Object,
    index: Number,
    isEdit: Boolean
  },
  mounted() {
    this.title = this.item.title;
    this.src = this.item.src;
    this.display = this.item.display;
    this.sort = this.item.sort;
  },
  computed: {
    classStyle() {
      return {
        "is-hidden": this.display === "delete",
        "is-gray": this.display === "none"
      };
    }
  },
  methods: {
    changeTitle(e) {
      const newValue = e.target.value;
      if (!newValue) {
        alert("タイルを入力してください。");
        e.target.focus();
      } else {
        this.notice_show = true;
        this.$emit("edit", "title", this.index, newValue);
      }
    },
    changeSrc(e) {
      const file = e.target.files[0];
      if (!file) {
        alert("写真を選択してください");
        return;
      }
      this.notice_show = true;
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          this.src = reader.result + "#" + encodeURIComponent(file.name);
          this.$emit("edit", "src", this.index, this.src);
        },
        false
      );

      reader.readAsDataURL(file);
    },
    selectDisplay() {
      if (this.display === "delete") {
        if (
          confirm(
            "削除してよろしいですか？\n画像がダウンロード不可能になります。"
          )
        ) {
          this.notice_show = true;
          this.$emit("edit", "display", this.index, this.display);
        }
      } else {
        //none
        this.notice_show = true;
        this.$emit("edit", "display", this.index, this.display);
      }
    },
    changeSort(e) {
      const newValue = e.target.value;
      if (!newValue) {
        alert("並び順を入力してください。");
        e.target.focus();
      } else {
        this.notice_show = true;
        this.$emit("edit", "sort", this.index, this.sort, this.$el);
      }
    }
  },
  data() {
    return {
      src: "",
      title: "",
      sort: 1,
      display: "",
      notice_show: false
    };
  }
};
</script>
