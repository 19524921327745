<template>
  <div>
    <Loading v-bind="loadingObject" />
    <div class="tile is-ancestor">
      <div class="tile is-vertical">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification is-success">
              <p class="title">スライダーの修正</p>
              <div class="buttons" style="position:absolute;top:5px;right:5px">
                <button class="button is-danger" v-if="!isEdit && hasAuth" @click="editMode()">編集</button>
                <button class="button" v-if="isEdit && hasAuth" @click="isEdit = false">キャンセル</button>
                <button class="button is-danger" v-if="isEdit && hasAuth" @click="create()">新規作成</button>
                <button class="button is-danger" v-if="isEdit && hasAuth" @click="save()">公開予約</button>
              </div>
              <slider
                v-for="(item,index) in sortedList"
                :key="item.key"
                @edit="editItem"
                :item="item"
                :index="index"
                :isEdit="isEdit"
              />
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "./elements/loading.vue";
import slider from "./elements/slider.vue";
export default {
  name: "SliderEditor",
  components: { Loading, slider },
  mounted() {},
  methods: {
    editMode() {
      this.editList = this.sortedList;
      this.isEdit = true;
    },
    sortEditList(list) {
      list.sort((a, b) => {
        if (b.sort > a.sort) {
          return -1;
        } else if (b.sort === a.sort) {
          return 0;
        } else {
          return 1;
        }
      });
    },
    editItem(key, index, val, element) {
      const item = this.editList[index];
      if (item) {
        item[key] = val;
        item.$edit = true;
        this.$set(this.editList, index, item);
        if (key === "sort") {
          this.sortEditList(this.editList);
          //対象までスクロール
          if (element) {
            this.$nextTick(() => {
              element.scrollIntoView();
            });
          }
        }
      }
    },
    async save() {
      //こいつを全て保存する
      const saveItems = this.editList.filter(e => e.display !== "delete");
      //いったん画像をアップロード
      for (let item of saveItems) {
        if (item.src.indexOf("data:") === 0) {
          const data = item.src.split("#");
          const file = {
            data: data[0],
            name: decodeURIComponent(data[1]) || "none"
          };
          const url = await this.item.$uploadImage(file);
          item.src = url;
        }
      }
      //それぞれ保存
      try {
        const items = saveItems.map((e, i) => ({
          title: e.title,
          display: e.display,
          sort: i + 1,
          src: e.src,
          key: e.key
        }));
        await this.item.$update({ items });
        this.isEdit = false;
      } catch (e) {
        console.trace(e); // eslint-disable-line
        alert(
          "想定外のエラーが発生しました。ページを読み込みなおして再度実行してください。"
        );
      }
    },
    create() {
      const item = {
        display: "",
        sort: 1,
        key: Date.now() + "_0",
        title: "",
        src: "",
        $edit: true
      };
      this.editList.unshift(item);
    }
  },
  inject: ["providedState"],
  computed: {
    sortedList() {
      if (this.isEdit) {
        return this.editList;
      } else {
        const list = this.item.items.slice();
        list.forEach((item, index) => {
          item.display = item.display || "";
          item.key = Date.now() + "_" + index;
        });
        this.sortEditList(list);
        return list;
      }
    },
    items() {
      return this.providedState.contents["Slider"];
    },
    item() {
      return this.items[0];
    },
    hasAuth() {
      return this.items && this.items.$hasAuth();
    }
  },
  data() {
    return {
      editList: [],
      isEdit: false,
      editTarget: null,
      loadingObject: {
        active: false,
        title: "処理中です。しばらくお待ちください。"
      }
    };
  }
};
</script>
