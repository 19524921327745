import staticContents from "./assets/contents.json"
import config from "./config.json"
import fire from "./fire"
let docs = {
  releaseTimestamp: Date.now(),
  contents: staticContents,
  isDevelop: false,
  currentUser: {
    uid: undefined
  },
  currentData: {
    rule: {}
  },
  async init() {
  }
}
//debugger; // eslint-disable-line
//window.f = fire;
//console.log(process.env)// eslint-disable-line

if (process.env.VUE_APP_develop === "true") {
  //firebase側の開発中コンテンツを登録
  /* vue でhookするためキーのみ設定 */
  const contents = {}
  window.c = contents;
  docs.staticContents = config.contents.reduce((p, c) => {
    p[c.key] = [];
    return p;
  }, {});
  Object.keys(docs.staticContents).forEach(k => contents[k] = [])
  docs.contents = contents
  docs.isDevelop = true

  const loadPrpmises = []
  //debugger; // eslint-disable-line

  docs.init = async (usr) => {
    //ログイン済み or null→ログイン or ログイン→null
    const uid = usr ? usr.uid : null;
    //ユーザ情報に差異がなければ更新しない
    if (docs.currentUser.uid === uid) {
      //前回のプロミス完了チェック
      await Promise.all(loadPrpmises)
      return
    } else if (uid === null) {
      //Logooff時は静的コンテンツを設定
      Object.keys(contents).forEach(key => {
        contents[key].splice(0, contents[key].length)
        docs.staticContents[key].forEach(data => contents[key].push(data));
        contents[key].$hasAuth = () => {
          return false
        }
      })
      return
    } else {
      //ログイン時
      docs.currentUser = usr;
      const userData = await fire.userData();
      const rule = userData.rule || {};
      docs.currentData = userData;
      //別ログインされた場合を考慮し、権限のみ取り直し（参照は全ユーザOKの想定)
      for (let key of Object.keys(contents)) {
        //コンテンツクリア
        contents[key].splice(0, contents[key].length)
        //FIXME:Write以外の権限は後程実装
        const authVal = { write: true };
        const hasAuth = Object.keys(rule).some(k => {
          return (
            k === key &&
            Object.keys(authVal).some(
              kk => rule[k][kk] === authVal[kk]
            )
          );
        });
        contents[key].$hasAuth = () => {
          return hasAuth
        }
      }
    }

    for (let key of Object.keys(contents)) {
      const cnt = new fire.Contents(key, usr)
      //更新・削除は$update,$deleteからやる
      contents[key].$contents = cnt;
      cnt.addEvent = item => {
        contents[key].push(item)
      }
      cnt.modifyEvent = item => {
        const targetIndex = contents[key].findIndex(c => item._doc.id === c._doc.id)
        if (targetIndex < 0) {
          contents[key].push(item)
        } else {
          const updItem = contents[key].splice(targetIndex, 1, item)
          updItem[0].$isChange = true;
        }
      }
      cnt.removeEvent = item => {
        const targetIndex = contents[key].findIndex(c => item._doc.id === c._doc.id)
        if (targetIndex < 0) {
          //none
        } else {
          const updItem = contents[key].splice(targetIndex, 1);
          updItem[0].$isChange = true;
        }
      }

      const load = cnt.init()
      loadPrpmises.push(load)
    }
    await Promise.all(loadPrpmises)
    //debugger; // eslint-disable-line

  }
} else {
  //静的コンテンツを設定
  Object.keys(staticContents).forEach(key => {
    staticContents[key].$hasAuth = () => {
      return false
    }
  })
}


const store = {
  docs
}

export default store