<template>
  <div class="notification">
    <div class="modal" v-if="showEditor" :class="{ 'is-active': true }">
      <div class="modal-background" @click="showEditor = false"></div>
      <contents-editor
        class="modal-content"
        style="width: 100%"
        :target="editTarget"
        contents-label="スライドメッセージの登録"
        @close="showEditor = false"
        :savekeys="savekeys"
        :option="editOption"
      >
        <template #default="slotProps">
          <div class="field">
            <div class="control">
              <select class="select" v-model="slotProps.content.enable">
                <option value="1">表示</option>
                <option value="">非表示</option>
              </select>
            </div>
          </div>
        </template>
      </contents-editor>
    </div>
    <p class="title">スライドメッセージの編集</p>
    <div class="buttons" style="position: absolute; top: 5px; right: 5px">
      <button class="button is-danger" v-if="hasAuth" @click="edit()">
        編集
      </button>
    </div>
    <div style="mergin-top: 30px">
      <slidemessage />
    </div>
  </div>
</template>
<script>
import ContentsEditor from "../ContentsEditor.vue";
import slidemessage from "./slide-message.vue";
export default {
  name: "slider-message-edit",
  components: { ContentsEditor, slidemessage },
  inject: ["providedState"],
  props: {},
  methods: {
    edit() {
      this.showEditor = true;
      if (this.items[0]) {
        this.editTarget = this.items[0];
      } else {
        this.editTarget = this.items.$contents.appendDoc();
      }
      this.editTarget.enable = this.editTarget.enable === "1" ? "1": "";
    },
  },
  mounted() {},
  computed: {
    hasAuth() {
      return this.items && this.items.$hasAuth();
    },
    items() {
      return this.providedState.contents["SlideMessage2"];
    },
  },
  data() {
    return {
      showEditor: false,
      editTarget: null,
      editOption: {
        body: true,
      },
      savekeys: ["enable"],
    };
  },
};
</script>
