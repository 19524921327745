<template>
  <div>
    <Loading v-bind="loadingObject" />
    <Modal v-bind="modalProps" v-if="showModal" @close="showModal = false" />
    <div class="modal" v-if="showEditor" :class="{'is-active':true}">
      <div class="modal-background" @click="showEditor = false"></div>
      <contents-editor
        class="modal-content"
        style="width: 100%;"
        :target="editTarget"
        contents-label="大会結果の記事登録"
        :savekeys="savekeys"
        @close="showEditor = false"
      >
      <template #default="slotProps">
        <div class="field is-grouped">
          <div class="control">
            <input list="slotProps.content.year" class="input" type="text" v-model="slotProps.content.year" placeholder="年度" />
            <datalist id="slotProps.content.year">
              <option v-for="year in yearList" :key="year" :value="year"></option>
            </datalist>
          </div>
        </div>
      </template>
      </contents-editor>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-vertical">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification is-success">
              <p class="title">大会結果</p>
              <button
                class="button is-danger"
                v-if="hasAuth"
                @click="create()"
                style="position:absolute;top:5px;right:5px"
              >新規作成</button>
            <nav class="panel" v-for="(g,g_index) in groupByItems" :key="g_index">
              <details :open="g_index === 0 ? true : false">
                <summary class="panel-heading" style="border-radius: 6px;">
                  {{g[0].year || "年度未設定"}}
                </summary>
              <div
                class="box"
                style="position:relative"
                v-for="(t,index) in g"
                :key="index"
              >
                <article class="media is-block-mobile">
                  <button
                    class="button is-danger"
                    v-if="items.$hasAuth()"
                    @click="edit(t)"
                    style="position:absolute;top:5px;right:5px;height: 2em;"
                  >編集</button>
                  <div class="media-left" v-if="t.thumbnailSrc">
                    <figure class="image is-128x128">
                      <img :src="t.thumbnailSrc" alt="Image" />
                    </figure>
                  </div>
                  <div class="media-content">
                    <div class="content">
                      <p>
                        <strong>{{t.title}}</strong>
                      </p>
                      <p v-html="t.body.html"></p>
                      <p v-if="t.detailBody && t.detailBody.html"><a @click="show(t)">続きを読む</a></p>
                    </div>
                  </div>
                </article>
              </div>
              </details>
              </nav>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {content as compatible_content} from "../compatible.js";
import Modal from "./elements/modal.vue";
import Loading from "./elements/loading.vue";
import ContentsEditor from "./ContentsEditor.vue";
export default {
  name: "GameResults",
  components: { Modal, Loading, ContentsEditor },
  methods: {
    edit(target) {
      this.showEditor = true;
      this.editTarget = target;
    },
    create() {
      this.showEditor = true;
      this.editTarget = this.items.$contents.appendDoc();
      this.editTarget.year = (new Date()).getFullYear() + "年度";
    },
    show(item) {
      this.showModal = true;
      this.modalProps = {
        title: item.title,
        html: item.detailBody.html
      };
    }
  },
  inject: ["providedState"],
  computed: {
    yearList(){
      return [0,-1,-2,-3].map(year=>{
        return ((new Date()).getFullYear() + year) + "年度"
      });
    },
    groupByItems() {
      const ret = [];
      this.sortedItems.reduce((p,c,i,org)=>{
        if(org[i-1] && org[i-1].year !== c.year){
          p.push([c]);
        }else{
          const last = p.pop() || [];
          last.push(c);
          p.push(last);
        }
        return p;
      },ret);
      return ret;
    },
    sortedItems() {
      const ret = this.items
        .map(e=>{
          if(!e.year) e.year = "2019年度";
          return e;
        })
        .sort((a, b) => {
          if(a.year === b.year){
            return (b.updated_at > a.updated_at ? 1 : -1);
          }else{
            return this.yearList.indexOf(a.year) > this.yearList.indexOf(b.year) ? 1 : -1;
          }
        });
        ret.forEach(e=>{
          //thumbnailSrcの互換設定
          //debugger; // eslint-disable-line
          compatible_content.thumbnailSrc(e);
          compatible_content.toggleDetailBody(e);
          compatible_content.modifyHtmlImage(e,"detailBody");
        });
        return ret;
    },
    items() {
      return this.providedState.contents["GameResults"];
    },
    hasAuth() {
      return this.items && this.items.$hasAuth();
    }
  },
  data() {
    return {
      showEditor: false,
      editTarget: null,
      loadingObject: {
        active: false,
        title: "処理中です。しばらくお待ちください。"
      },
      showModal: false,
      modalProps: {},
      savekeys:["year"]
    };
  }
};
</script>