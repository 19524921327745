const util = {
    formatDate(date, format) {
        if (!format) format = 'YYYY-MM-DD hh:mm:ss';
        format = format.replace(/YYYY/g, date.getFullYear());
        format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
        format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
        format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
        format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
        format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
        if (format.match(/S/g)) {
            const milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
            const length = format.match(/S/g).length;
            for (let i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
        }
        return format;
    },
    imageResize(base64src,max_width,max_height){
      return new Promise(resolve=>{
        const image = new Image();
          image.onload = function() {
            let width, height,ratio;
            if(image.width > image.height){
              ratio = image.height/image.width;
              width = max_width;
              height = max_width * ratio;
            } else {
              ratio = image.width/image.height;
              width = max_height * ratio;
              height = max_height;
            }
            const canvas = document.createElement("canvas");
            canvas.setAttribute("width",width);
            canvas.setAttribute("height",height);
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image,0,0,image.width,image.height,0,0,width,height);
    
            resolve(canvas.toDataURL('image/jpeg'));
          };
          image.src = base64src;
      });
    }
}
export default util