<template>
  <div>
    <banner />
    <div style="width: 100%;text-align: center;margin-bottom: 10px;">
      <div ref="buttons" class="buttons" style=" display: inline-block;">
        <router-link class="button is-link" to="/" exact-active-class="is-outlined">ホーム</router-link>
        <router-link class="button is-link" to="/組織案内" exact-active-class="is-outlined">組織案内</router-link>
        <router-link class="button is-link" to="/大会結果" exact-active-class="is-outlined">大会結果</router-link>
        <router-link class="button is-link" to="/トピックス" exact-active-class="is-outlined">トピックス</router-link>
        <router-link
          class="button is-link"
          to="/ダウンロード"
          exact-active-class="is-outlined"
        >ダウンロード</router-link>
        <router-link
          v-if="isDevelop"
          class="button is-danger"
          to="/会員専用"
          exact-active-class="is-outlined"
        >会員専用</router-link>
        <router-link
          v-if="isDevelop"
          class="button is-danger"
          to="/管理者用"
          exact-active-class="is-outlined"
        >管理者用</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import banner from "./elements/banner.vue";
export default {
  name: "Menu",
  mounted() {
    //debugger; // eslint-disable-line
    this.$refs.buttons.addEventListener(
      "click",
      () => {
        setTimeout(() => {
          document.activeElement.blur();
        }, 100);
      },
      false
    );
  },
  components: {
    banner
  },
  inject: ["providedState"],
  computed: {
    isDevelop() {
      return this.providedState.isDevelop === true;
    }
  },
  props: {},
  data() {
    return {
      menuIsActive: false
    };
  }
};
</script>
