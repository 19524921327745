<template>
  <div>
    <WideSlider v-if="fgSliderRecreate" @recreate="sliderRecreate" />
    <slidemessage/>
    <div style="margin: 1rem">
      <div class="tile is-ancestor">
        <div class="tile">
          <div class="tile is-parent">
            <tile
              :items="sortFilter(contents['Topics'])"
              title="トピックス"
              path="トピックス"
              style="background-color: #acf2ad"
            />
          </div>
        </div>
        <div class="tile">
          <div class="tile is-parent">
            <tile
              :items="sortFilter(contents['Notice'])"
              title="お知らせ"
              path="お知らせ"
              style="background-color: #f3ffae"
            />
          </div>
        </div>
      </div>
      <div class="tile is-ancestor">
        <div class="tile is-parent">
          <category
            :items="contents['SkillUp']"
            title="指導・普及"
            path="指導・普及"
            style="background-color: #bac9f6"
          />
        </div>
        <div class="tile is-parent">
          <category
            :items="contents['Special']"
            title="特集コーナー"
            path="特集コーナー"
            style="background-color: #f9c1ba"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WideSlider from "./WideSlider.vue";
import tile from "./elements/tile.vue";
import category from "./elements/category.vue"
import slidemessage from "./elements/slide-message.vue"

export default {
  name: "Top",
  inject: ["providedState"],
  computed: {
    contents() {
      return this.providedState.contents || {};
    },
  },
  watch: {},
  methods: {
    sortFilter(contents) {
      return contents
        .slice()
        .sort((a, b) => (b.updated_at > a.updated_at ? 1 : -1))
        .filter((e, i) => i < 5);
    },
    sliderRecreate() {
      this.fgSliderRecreate = false;
      this.$nextTick(() => {
        this.fgSliderRecreate = true;
      });
    },
  },
  components: {
    WideSlider,
    tile,
    category,
    slidemessage
  },
  async mounted() {},
  data() {
    return {
      fgSliderRecreate: true,
    };
  },
};
</script>

<style lang="scss">
</style>