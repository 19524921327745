<template>
   <div>
    <Loading v-bind="loadingObject" />
    <Modal v-bind="modalProps" v-if="showModal" @close="showModal = false" />
    <div class="modal" v-if="showEditor" :class="{ 'is-active': true }">
      <div class="modal-background" @click="showEditor = false"></div>
      <contents-editor
        class="modal-content"
        style="width: 100%"
        :target="editTarget"
        contents-label="ダウンロードの登録"
        :savekeys="savekeys"
        @close="showEditor = false"
      >
        <template #default="slotProps">
          <div class="field is-grouped">
            <div class="control">
              <input
                list="slotProps.content.category"
                class="input"
                type="text"
                v-model="slotProps.content.category"
                placeholder="カテゴリ"
              />
              <p class="message is-primary">クリックしてダウンロードする機能は「確認用環境 or Production環境リリース後」に適用されます。</p>
            </div>
          </div>
        </template>
      </contents-editor>
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-vertical">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article
              class="tile is-child notification"
              style="background-color: #bac9f6"
            >
              <p class="title">ダウンロード</p>
              <button
                class="button is-danger"
                v-if="hasAuth"
                @click="create()"
                style="position: absolute; top: 5px; right: 5px"
              >
                新規作成
              </button>
              <nav
                class="panel"
                v-for="(g, g_index) in groupByItems"
                :key="g_index"
              >
                <details :open="g_index === 0 ? true : false">
                  <summary class="panel-heading" style="border-radius: 6px">
                    {{ g[0].category || "未設定" }}
                  </summary>
                  <div
                    class="box"
                    style="position: relative"
                    v-for="(t, index) in g"
                    :key="index"
                  >
                    <article class="media is-block-mobile">
                      <button
                        class="button is-danger"
                        v-if="hasAuth"
                        @click="edit(t)"
                        style="position: absolute; top: 5px; right: 5px"
                      >
                        編集
                      </button>
                      <div class="media-left" v-if="t.thumbnailSrc">
                        <figure class="image is-128x128">
                          <img :src="t.thumbnailSrc" alt="Image" />
                        </figure>
                      </div>
                      <div class="media-content">
                        <div class="content">
                          <p>
                            <strong>{{ t.title }}</strong>
                          </p>
                          <p v-html="a2download(t.body.html)"></p>
                          <p v-if="t.detailBody && t.detailBody.html">
                            <a @click="show(t)">続きを読む</a>
                          </p>
                        </div>
                      </div>
                    </article>
                  </div>
                </details>
              </nav>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "./elements/modal.vue";
import Loading from "./elements/loading.vue";
import ContentsEditor from "./ContentsEditor.vue";
export default {
  name: "Download",
  components: { Modal, Loading, ContentsEditor },
  methods: {
    a2download(html){
      const domparser = new DOMParser();
      const doc = domparser.parseFromString(html, "text/html");
      [...doc.querySelectorAll("a")].forEach(e=>{
        e.setAttribute("download","");
      })
      return doc.body.innerHTML;
    },
    edit(target) {
      this.showEditor = true;
      this.editTarget = target;
    },
    create() {
      this.showEditor = true;
      this.editTarget = this.items.$contents.appendDoc();
    this.editTarget.category = "未設定"
    },
    show(item) {
      this.showModal = true;
      const html = this.a2download(item.detailBody.html);
      this.modalProps = {
        title: item.title,
        html
      };
    },
  },
  inject: ["providedState"],
  computed: {
    groupByItems() {
      const ret = [];
      this.sortedItems.reduce((p, c, i, org) => {
        if (org[i - 1] && org[i - 1].category !== c.category) {
          p.push([c]);
        } else {
          const last = p.pop() || [];
          last.push(c);
          p.push(last);
        }
        return p;
      }, ret);
      return ret;
    },
    sortedItems() {
      return this.items
        .map((e) => {
          if (!e.category) e.category = "未設定";
          return e;
        })
        .sort((a, b) => {
          if (a.category === b.category) {
            return b.updated_at > a.updated_at ? 1 : -1;
          } else {
            return a.category > b.category ? 1 : -1;
          }
        });
    },
    items() {
      return this.providedState.contents["Download"];
    },
    hasAuth() {
      return this.items && this.items.$hasAuth();
    },
  },
  data() {
    return {
      showEditor: false,
      editTarget: null,
      loadingObject: {
        active: false,
        title: "処理中です。しばらくお待ちください。",
      },
      showModal: false,
      modalProps: {},
      savekeys: ["category"],
    };
  },
};
</script>
