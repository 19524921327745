<template>
  <div>
    <Loading v-bind="loadingObject" />
    <div class="tile is-ancestor">
      <div class="tile is-vertical">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification is-success">
              <div class="box">
                <p class="title">ログイン</p>
                <p v-show="msg" class="subtitle">{{msg}}</p>
                <div class="field" v-if="showPhoneNumberInput">
                  <div class="control">
                    <label class="label">携帯電話の番号を入力して下さい。SMSに確認コードが送信されます。</label>
                    <input class="input" type="tel" v-model="phoneNumber" placeholder="電話番号" />
                  </div>
                  <div class="control">
                    <button id="sign-in-button" class="button" @click="login">確認コードを送信</button>
                  </div>
                </div>
                <div class="field" v-if="showConfirmCode">
                  <label class="label">確認コードを入力してください。</label>
                  <div class="control">
                    <input class="input" type="text" v-model="code" placeholder="確認コード" />
                  </div>
                  <div class="control">
                    <button class="button" @click="confirm">登録</button>
                  </div>
                </div>
                <div class="field" v-if="showConfirmName">
                  <label class="label">お名前を入力してください。</label>
                  <div class="control">
                    <input class="input" type="text" v-model="displayName" placeholder="お名前" />
                  </div>
                  <div class="control">
                    <button class="button" @click="confirmName">登録</button>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "./elements/loading.vue";

import fire from "../fire.js";
const firebase = fire.base;

export default {
  name: "Login",
  props: {
    msg: String
  },
  components: { Loading },
  mounted() {
    this.showPhoneNumberInput = true;
    this.showConfirmCode = false;
    this.showConfirmName = false;
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible"
      }
    );
    //ログイン済みだったら飛ばす
    if (firebase.auth().currentUser) {
      this.$router.push(this.$route.query.redirect || "/");
    }
  },
  data: function() {
    return {
      loadingObject: {
        active: false,
        title: "登録処理中です。しばらくお待ちください。"
      },
      recaptchaVerifier: null,
      phoneNumber: "",
      code: "",
      verificationId: null,
      showPhoneNumberInput: true,
      showConfirmCode: false,
      displayName: "",
      showConfirmName: false
    };
  },
  computed: {
    fixedPhoneNumber() {
      if (this.phoneNumber) {
        if (this.phoneNumber.indexOf("+") === 0) {
          return this.phoneNumber;
        } else if (this.phoneNumber.indexOf("0") === 0) {
          return "+81" + this.phoneNumber.substr(1);
        }
      }
      return "";
    }
  },
  methods: {
    async login() {
      if (!this.fixedPhoneNumber) {
        alert("SMSが受信可能な電話番号を入力してください。");
        return;
      }
      firebase.auth().languageCode = "jp";
      this.loadingObject.active = true;
      try {
        const provider = new firebase.auth.PhoneAuthProvider();
        const verificationId = await provider.verifyPhoneNumber(
          this.fixedPhoneNumber,
          this.recaptchaVerifier
        );
        this.showPhoneNumberInput = false;
        this.showConfirmCode = true;
        this.verificationId = verificationId;
      } catch (e) {
        alert("SMSを受信可能な電話番号を入力してください。");
        console.log(e); // eslint-disable-line
      }
      this.loadingObject.active = false;
    },
    async confirmName() {
      if (!this.displayName) {
        alert("お名前を入力してください");
        return;
      } else {
        this.loadingObject.active = true;
        try {
          await firebase.auth().currentUser.updateProfile({
            displayName: this.displayName
          });
        } catch (e) {
          alert("更新処理に失敗しました。");
          console.log(e); // eslint-disable-line
        }
        this.loadingObject.active = false;
        this.$router.push(this.$route.query.redirect || "/");
        //FIXME:諦めた
        this.$nextTick(() => location.reload());
      }
    },
    async confirm() {
      if (!this.code) {
        alert("確認コードを入力してください");
        return;
      }
      this.loadingObject.active = true;
      try {
        const phoneCredential = await firebase.auth.PhoneAuthProvider.credential(
          this.verificationId,
          this.code
        );
        await firebase.auth().signInWithCredential(phoneCredential);
        this.showConfirmCode = false;
        //ここらで新規ユーザ（名前なし）の場合は名前を必須にするかな
        if (firebase.auth().currentUser.displayName) {
          this.$router.push(this.$route.query.redirect || "/");
          //FIXME:諦めた
          this.$nextTick(() => location.reload());
        } else {
          this.showConfirmName = true;
        }
      } catch (e) {
        alert("確認コードが正しくありません。");
        console.log(e); // eslint-disable-line
      }
      this.loadingObject.active = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
