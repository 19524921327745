<template>
  <div class>
    <div class="message is-warning" style :class="{'is-hidden':!isShow}">
      <div class="message-body">{{msg}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fixed-notice",
  mounted() {
    this.trigger();
  },
  watch: {
    isShow() {
      this.trigger();
    }
  },
  props: {
    msg: String,
    isShow: Boolean
  },

  methods: {
    trigger() {
      if (this.isShow) {
        this.show();
      } else {
        this.hide();
      }
    },
    show() {
      //document.body.classList.add("has-navbar-fixed-top");
    },
    hide() {
      //document.body.classList.remove("has-navbar-fixed-top");
    }
  },
  data() {
    return {};
  }
};
</script>
