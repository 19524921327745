<template>
  <div class="pageloader" :class="{'is-active':active}">
    <span class="title">{{fixedTitle}}</span>
  </div>
</template>

<script>
export default {
  name: "Loading",
  props: {
    title: String,
    active: Boolean
  },
  computed: {
    fixedTitle() {
      return this.title || "読み込み中";
    }
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pageloader {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.pageloader {
  position: fixed;
  padding-top: 2em;
  background: #00d1b2;
  background: #00d1b2;
  z-index: 999998;
  transition: transform 0.35s ease-out, -webkit-transform 0.35s ease-out;
  will-change: transform;
}

.pageloader.is-white {
  background-color: #fff;
  background: #fff;
}

.pageloader.is-white::after {
  border-color: #0a0a0a;
  -webkit-animation: loader-figure-white 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure-white 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure-white 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader.is-white .title {
  color: #0a0a0a;
}

.pageloader.is-black {
  background-color: #0a0a0a;
  background: #0a0a0a;
}

.pageloader.is-black::after {
  border-color: #fff;
  -webkit-animation: loader-figure-black 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure-black 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure-black 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader.is-black .title {
  color: #fff;
}

.pageloader.is-light {
  background-color: #f5f5f5;
  background: #f5f5f5;
}

.pageloader.is-light::after {
  border-color: #363636;
  -webkit-animation: loader-figure-light 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure-light 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure-light 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader.is-light .title {
  color: #363636;
}

.pageloader.is-dark {
  background-color: #363636;
  background: #363636;
}

.pageloader.is-dark::after {
  border-color: #f5f5f5;
  -webkit-animation: loader-figure-dark 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure-dark 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure-dark 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader.is-dark .title {
  color: #f5f5f5;
}

.pageloader.is-primary {
  background-color: #00d1b2;
  background: #00d1b2;
}

.pageloader.is-primary::after {
  border-color: #fff;
  -webkit-animation: loader-figure-primary 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure-primary 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure-primary 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader.is-primary .title {
  color: #fff;
}

.pageloader.is-link {
  background-color: #3273dc;
  background: #3273dc;
}

.pageloader.is-link::after {
  border-color: #fff;
  -webkit-animation: loader-figure-link 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure-link 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure-link 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader.is-link .title {
  color: #fff;
}

.pageloader.is-info {
  background-color: #209cee;
  background: #209cee;
}

.pageloader.is-info::after {
  border-color: #fff;
  -webkit-animation: loader-figure-info 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure-info 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure-info 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader.is-info .title {
  color: #fff;
}

.pageloader.is-success {
  background-color: #23d160;
  background: #23d160;
}

.pageloader.is-success::after {
  border-color: #fff;
  -webkit-animation: loader-figure-success 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure-success 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure-success 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader.is-success .title {
  color: #fff;
}

.pageloader.is-warning {
  background-color: #ffdd57;
  background: #ffdd57;
}

.pageloader.is-warning::after {
  border-color: rgba(0, 0, 0, 0.7);
  -webkit-animation: loader-figure-warning 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure-warning 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure-warning 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader.is-warning .title {
  color: rgba(0, 0, 0, 0.7);
}

.pageloader.is-danger {
  background-color: #ff3860;
  background: #ff3860;
}

.pageloader.is-danger::after {
  border-color: #fff;
  -webkit-animation: loader-figure-danger 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-animation: loader-figure-danger 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure-danger 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader.is-danger .title {
  color: #fff;
}

.pageloader:not(.is-left-to-right),
.pageloader:not(.is-right-to-left) {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.pageloader.is-left-to-right {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.pageloader.is-right-to-left {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.pageloader.is-active:not(.is-left-to-right),
.pageloader.is-active:not(.is-right-to-left) {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.pageloader.is-active.is-left-to-right,
.pageloader.is-active.is-right-to-left {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.pageloader::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  border-radius: 100%;
  content: "";
  z-index: 9999;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  box-sizing: border-box;
  border: 0 solid #fff;
  -webkit-animation: loader-figure 1.15s infinite
    cubic-bezier(0.215, 0.61, 0.355, 1);
  animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}

.pageloader .title {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 2em 0 0 0;
  font-size: 0.875em;
  letter-spacing: 0.1em;
  line-height: 1.5em;
  color: #fff;
  white-space: nowrap;
}

@-webkit-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-figure-white {
  0% {
    height: 0;
    width: 0;
    background-color: #0a0a0a;
  }

  29% {
    background-color: #0a0a0a;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure-white {
  0% {
    height: 0;
    width: 0;
    background-color: #0a0a0a;
  }

  29% {
    background-color: #0a0a0a;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure-white {
  0% {
    height: 0;
    width: 0;
    background-color: #0a0a0a;
  }

  29% {
    background-color: #0a0a0a;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure-white {
  0% {
    height: 0;
    width: 0;
    background-color: #0a0a0a;
  }

  29% {
    background-color: #0a0a0a;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-figure-black {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure-black {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure-black {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure-black {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-figure-light {
  0% {
    height: 0;
    width: 0;
    background-color: #363636;
  }

  29% {
    background-color: #363636;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure-light {
  0% {
    height: 0;
    width: 0;
    background-color: #363636;
  }

  29% {
    background-color: #363636;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure-light {
  0% {
    height: 0;
    width: 0;
    background-color: #363636;
  }

  29% {
    background-color: #363636;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure-light {
  0% {
    height: 0;
    width: 0;
    background-color: #363636;
  }

  29% {
    background-color: #363636;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-figure-dark {
  0% {
    height: 0;
    width: 0;
    background-color: #f5f5f5;
  }

  29% {
    background-color: #f5f5f5;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure-dark {
  0% {
    height: 0;
    width: 0;
    background-color: #f5f5f5;
  }

  29% {
    background-color: #f5f5f5;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure-dark {
  0% {
    height: 0;
    width: 0;
    background-color: #f5f5f5;
  }

  29% {
    background-color: #f5f5f5;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure-dark {
  0% {
    height: 0;
    width: 0;
    background-color: #f5f5f5;
  }

  29% {
    background-color: #f5f5f5;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-figure-primary {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure-primary {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure-primary {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure-primary {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-figure-link {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure-link {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure-link {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure-link {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-figure-info {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure-info {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure-info {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure-info {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-figure-success {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure-success {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure-success {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure-success {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-figure-warning {
  0% {
    height: 0;
    width: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  29% {
    background-color: rgba(0, 0, 0, 0.7);
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure-warning {
  0% {
    height: 0;
    width: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  29% {
    background-color: rgba(0, 0, 0, 0.7);
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure-warning {
  0% {
    height: 0;
    width: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  29% {
    background-color: rgba(0, 0, 0, 0.7);
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure-warning {
  0% {
    height: 0;
    width: 0;
    background-color: rgba(0, 0, 0, 0.7);
  }

  29% {
    background-color: rgba(0, 0, 0, 0.7);
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-webkit-keyframes loader-figure-danger {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-moz-keyframes loader-figure-danger {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@-o-keyframes loader-figure-danger {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}

@keyframes loader-figure-danger {
  0% {
    height: 0;
    width: 0;
    background-color: #fff;
  }

  29% {
    background-color: #fff;
  }

  30% {
    height: 2em;
    width: 2em;
    background-color: transparent;
    border-width: 1em;
    opacity: 1;
  }

  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
    background-color: transparent;
  }
}
</style>
