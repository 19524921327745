<template>
  <article class="tile is-child box">
    <categoryChild v-bind="modalProps" v-if="showModal" @close="showModal = false" />
    <p class="title" style="position:relative">
      {{title}}
      <router-link
        class="button is-small"
        style="position: absolute;bottom: 12px;
    right: 0;"
        :to="{path}"
      >一覧へ</router-link>
    </p>
    <div v-for="(i,index) in categorys" :key="index" class="list is-hoverable is-fullwidth">
      <a
        class="list-item"
        style="text-align: left;justify-content: left;"
        @click="show(i)"
      >▶︎{{i}}</a>
    </div>
  </article>
</template>

<script>
import categoryChild from "./categoryChild.vue";
export default {
  name: "category",
  components: {
    categoryChild
  },
  props: {
    items: Array,
    title: String,
    path: String
  },
  computed: {
    sortedItems() {
      return this.items
        .map((e) => {
          if (!e.category) e.category = "未設定";
          return e;
        })
        .sort((a, b) => {
          if (a.category === b.category) {
            return b.updated_at > a.updated_at ? 1 : -1;
          } else {
            return a.category > b.category ? 1 : -1;
          }
        });
    },
    categorys(){
      return this.sortedItems
        .map(e=>e.category)
        .filter((e,i,org)=>i === org.indexOf(e))
    }
  },
  methods: {
    show(category) {
      this.showModal = true;
      this.modalProps = {
        title: category,
        items:this.sortedItems.filter(e=>e.category === category)
      };
    }
  },
  data() {
    return {
      showModal: false,
      modalProps: {}
    };
  }
};
</script>
