export const content = {
    modifyHtmlImage(c,key="body"){
      //キーの有無によらず指定されたキーをコンバート
      if(c && c.hasOwnProperty && c.hasOwnProperty(key)){
        try{
            const parser = new DOMParser();
            const doc = parser.parseFromString(c[key].html, "text/html");
            //複数画像が未改行で存在する場合は、カラムにする。
            [...doc.body.querySelectorAll("p")].forEach(p=>{
              const images = p.querySelectorAll("img");
              if(images.length > 1){
                p.classList.add("columns");
                p.classList.add("is-multiline");
                [...images].forEach(i=>{
                  i.classList.add("column");
                  i.classList.add("box");
                  i.classList.add("is-4");
                })
              }
            });
            c[key].html = doc.body.innerHTML;
        }catch(e){
            //no
        }
      }
    },
    toggleDetailBody(c){
      //キーがないときにBodyをDetailbodyにMoveする
      if(c && c.hasOwnProperty && !c.hasOwnProperty("detailBody")){
        c.detailBody = {
          html:c.body.html,
          type:"inner"
        }
        c.body.html="";
      }
    },
    thumbnailSrc(c){
      //キーがない時に最初の画像をサムネにする
      if(c && c.hasOwnProperty && !c.hasOwnProperty("thumbnailSrc")){
          c.thumbnailSrc = "";
          try{
              const parser = new DOMParser();
              const doc = parser.parseFromString(c.body.html, "text/html");
              const firstImg = doc.querySelector("img");
              if(firstImg){
                  c.thumbnailSrc = firstImg.src;
              }
          }catch(e){
              //no
          }
      }
    }
              /*
          [...doc.querySelectorAll("img")].forEach((img,index)=>{
            if(index>1 && img.parentNode){
              img.parentNode.removeChild(img);
            }else{
              const figure = document.createElement("figure");
              img.insertAdjacentElement("beforebegin",figure);
              figure.classList.add("is-128x128");
              figure.classList.add("image");
              figure.appendChild(img);
            }
          });
          
          e.body.html = doc.body.innerHTML;
            */

}