<template>
  <div>
    <Loading v-bind="loadingObject" />
    <div style="width: 100%;text-align: center;margin-bottom: 10px;">
      <div class="buttons" style=" display: inline-block;">
        <router-link
          v-if="isDevelop"
          class="button"
          to="/スライダー編集"
          exact-active-class="is-outlined"
        >スライダー編集</router-link>
        <router-link
          v-if="isDevelop"
          class="button"
          to="/スライドメッセージ編集"
          exact-active-class="is-outlined"
        >スライドメッセージ編集</router-link>
        <router-link
          v-if="isDevelop && hasAuthAuthorityManagement"
          class="button"
          to="/権限管理"
          exact-active-class="is-outlined"
        >権限管理</router-link>
        <router-link
          v-if="isDevelop && hasAuthReleaseManagement"
          class="button"
          to="/リリース管理"
          exact-active-class="is-outlined"
        >リリース管理</router-link>
        <router-link
          v-if="isDevelop"
          class="button"
          to="/開発要望"
          exact-active-class="is-outlined"
        >開発要望</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "./elements/loading.vue";

export default {
  name: "AdminPage",
  components: { Loading },
  methods: {},
  inject: ["providedState"],
  computed: {
    isDevelop() {
      return this.providedState.isDevelop === true;
    },
    currentUserRule() {
      return this.providedState.currentData.rule || {};
    },
    hasAuthAuthorityManagement() {
      return (
        this.currentUserRule._users && this.currentUserRule._users.read === true
      );
    },
    hasAuthReleaseManagement() {
      return (
        this.currentUserRule._release &&
        this.currentUserRule._release.read === true
      );
    }
  },
  data() {
    return {
      loadingObject: {
        active: false,
        title: "処理中です。しばらくお待ちください。"
      }
    };
  }
};
</script>
