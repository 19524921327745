<template>
  <div>
    <header class="modal-card-head">
      <p class="modal-card-title">{{contentsLabel}}({{target.isEmpty ? "新規" : "更新"}})</p>
      <p v-if="disp_updated_at">更新時間：{{disp_updated_at}}</p>
      <button class="delete" aria-label="close" @click="$emit('close')"></button>
    </header>
    <section class="modal-card-body">
      <div class="message is-warning" :class="{'is-hidden':!target.$isChange}">
        <div class="message-header">
          <p>警告</p>
        </div>
        <div class="message-body">
          他のユーザがコンテンツを更新したため公開予約を抑制しました。
          <a @click="openSelf">こちらから</a>更新内容を確認して編集してください。
        </div>
      </div>
      <div class="field">
        <div class="control" v-if="option.title">
          <input class="input" type="text" v-model="title" placeholder="タイトル" />
        </div>
        <slot :content="content"></slot>
        <article class="media is-block-mobile">
          <div class="media-left"  v-if="option.thumbnailSrc">
            <label>サムネイル画像</label>
            <figure class="image is-128x128">
              <img v-if="thumbnailSrc" :src="thumbnailSrc" alt="Image" />
              <img v-else src="../assets/m_e_others_501.png" alt="NoImage" />
            </figure>
            <div class="field-body is-small">
              <div class="file">
                <label class="file-label">
                  <input class="file-input" type="file" accept="image/*" @change="changeSrc($event)" />
                  <span class="file-cta">
                    <span class="file-icon">
                      <i class="fas fa-upload"></i>
                    </span>
                    <span class="file-label">画像を選択</span>
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div class="media-content" v-if="option.body">
            <div class="content">
                <QuillEditor
                styles="min-height:200px;"
                placeholder="記事を入力してください。"
                ref="body"
                :html="body"
                @sync="body = $event.html"
              ></QuillEditor>
            </div>
          </div>
        </article>
      </div>
      <details :open="openDetail" v-if="option.detailBody">
        <summary>詳細の記事を入力</summary>
          <div class="field">
            <div class="control">
              <QuillEditor
                styles="min-height:200px;"
                placeholder="記事の詳細を入力してください。"
                ref="detailBody"
                :html="detailBody"
                @sync="detailBody = $event.html"
              ></QuillEditor>
            </div>
          </div>
      </details>
    </section>
    <footer v-if="!loading" class="modal-card-foot">
      <button class="button is-success" :disabled="target.$isChange" @click="save">公開予約</button>
      <button class="button" @click="$emit('close')">キャンセル</button>
      <button class="button is-warning" v-if="!target.isEmpty" @click="deleteTarget">削除</button>
    </footer>
    <footer v-else class="modal-card-foot">
      <progress class="progress is-info" :value="progress" max="100">45%</progress>
    </footer>

  </div>
</template>

<script>
import util from "../util";
export default {
  name: "ContentsEditor",
  components: {
    QuillEditor:() => import(/* webpackChunkName: "group-develop" */'./elements/quillEditor.vue')
  },
  props: {
    target: Object,
    contentsLabel: String,
    savekeys:  {
      type     : Array,
      require  : false,
      'default': () => ([]) 
    },
    option:{
      type :Object,
      require : false,
      'default':()=> ({title:true,body:true,detailBody:true,thumbnailSrc:true})
    }
  },
  methods: {
    openSelf(){
      window.open(location.href);
    },
    changeSrc(e) {
      const file = e.target.files[0];
      if (!file) {
        alert("写真を選択してください");
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load",async() => {
          const src = await util.imageResize(reader.result,125,125);
          this.thumbnailSrc = src+ "#" + encodeURIComponent(file.name);
      },false);

      reader.readAsDataURL(file);
    },
    async deleteTarget() {
      if (confirm("記事を削除しますか？")) {
        await this.target.$delete();
        this.$emit("close");
      }
    },
    async htmlConvert(orgHtml){
      const domparser = new DOMParser();
      const doc = domparser.parseFromString(orgHtml, "text/html");

      const images = [...doc.querySelectorAll("img")];
      // bodyの整形処理
      images.forEach(e => {
        e.style.removeProperty("cursor");
      });
      const files = [...doc.querySelectorAll("a")];

      const uploadTarget = {
        images:images.filter(e => e.src.indexOf("data:") === 0),
        files:files.filter(e => e.href.indexOf("data:") === 0)
      }
      let currentUploaded = 0;
      const uploadFileSize = uploadTarget.images.length + uploadTarget.files.length;
      const updatedProgress = ()=> this.progress = (++currentUploaded)/uploadFileSize * 100;
      for (let img of uploadTarget.images) {
        const data = img.src.split("#");
        const file = {
          data: data[0],
          name: decodeURIComponent(data[1]) || "none"
        };
        const url = await this.target.$uploadImage(file);
        img.src = url;
        updatedProgress();
      }

      for (let current of uploadTarget.files) {
        const data = current.href.split("#");
        const file = {
          data: data[0],
          name: decodeURIComponent(data[1]) || "none"
        };
        const url = await this.target.$uploadFile(file);
        current.href = url;
        updatedProgress();
      }
      return doc;
    },
    async save() {
      this.progress = 0;
      this.loading = true;
      const bodyDoc = await this.htmlConvert(this.body);
      const detailDoc = await this.htmlConvert(this.detailBody);
      //複数画像が未改行で存在する場合は、カラムにする。
      [...detailDoc.body.querySelectorAll("p")].forEach(p=>{
        const images = p.querySelectorAll("img");
        if(images.length > 1){
          p.classList.add("columns");
          p.classList.add("is-multiline");
          [...images].forEach(i=>{
            i.classList.add("column");
            i.classList.add("box");
            i.classList.add("is-4");
          })
        }
      });
      if(this.thumbnailSrc.indexOf("data:") === 0){
        const data = this.thumbnailSrc.split("#");
        const file = {
          data: data[0],
          name: decodeURIComponent(data[1]) || "none"
        };
        this.thumbnailSrc = await this.target.$uploadImage(file);
      }
      const data = {
        title: this.title,
        thumbnailSrc:this.thumbnailSrc,
        body: {
          type: "inner",
          html: bodyDoc.body.innerHTML
        },
        detailBody:{
          type: "inner",
          html: detailDoc.body.innerHTML
        }
      };
      for(let key of this.savekeys){
        data[key] = this.content[key];
      }
      try {
        await this.target.$update(data);
        this.$emit("close");
      } catch (e) {
        console.error(e); // eslint-disable-line
        //debugger;
        alert(
          "想定外のエラーが発生しました。ページを読み込みなおして再度実行してください。"
        );
      }
      this.loading = false;
    }
  },
  data() {
    return {
      quill: null,
      title: null,
      body: "",
      detailBody:"",
      openDetail:false,
      thumbnailSrc:"",
      disp_updated_at: null,
      content:{},
      loading:false,
      progress:0
    };
  },
  mounted() {
    if (this.target && !this.target.isEmpty) {
      this.body = this.target.body.html;
      if(this.target.detailBody){
        this.detailBody = this.target.detailBody.html;
        if(this.detailBody){
          this.openDetail = true;
        }
      }
      if(this.target.thumbnailSrc){
        this.thumbnailSrc = this.target.thumbnailSrc;
      }
      this.title = this.target.title;
      if (this.target.$getUpdated) {
        this.disp_updated_at = this.target.$getUpdated();
      } else {
        //初期登録のためなし
      }
    }
    this.content = {};
    for(let key of this.savekeys){
      this.$set(this.content,key,this.target[key] || "");
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*@import "~quill/dist/quill.snow.css";*/
</style>
