<template>
  <div>
    <Loading v-bind="loadingObject" />
    <div class="modal" v-if="showEditor" :class="{'is-active':true}">
      <div class="modal-background" @click="showEditor = false"></div>
      <contents-editor
        class="modal-content"
        style="width: 100%;"
        :target="editTarget"
        contents-label="スライダー編集"
        @close="showEditor = false"
      />
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-vertical">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification is-success">
              <slider-editor />
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "./elements/loading.vue";
import SliderEditor from "./スライダー.vue";

export default {
  name: "EditSlider",
  components: { Loading, SliderEditor },
  methods: {
    edit(target) {
      this.showEditor = true;
      this.editTarget = target;
    },
    create() {
      this.showEditor = true;
      this.editTarget = this.items.$contents.appendDoc();
    }
  },
  inject: ["providedState"],
  computed: {},
  data() {
    return {
      showEditor: false,
      editTarget: null,
      loadingObject: {
        active: false,
        title: "処理中です。しばらくお待ちください。"
      }
    };
  }
};
</script>
