<template>
  <article class="tile is-child box">
    <modal v-bind="modalProps" v-if="showModal" @close="showModal = false" />
    <p class="title" style="position:relative">
      {{title}}
      <router-link
        class="button is-small"
        style="position: absolute;bottom: 12px;
    right: 0;"
        :to="{path}"
      >一覧へ</router-link>
    </p>
    <div v-for="(i,index) in items" :key="index" class="list is-hoverable is-fullwidth">
      <a
        class="list-item"
        style="text-align: left;justify-content: left;"
        @click="show(i)"
      >{{i.title}}</a>
    </div>
  </article>
</template>

<script>
import modal from "./modal-item.vue";
export default {
  name: "tile",
  components: {
    modal
  },
  props: {
    items: Array,
    title: String,
    path: String
  },
  computed: {
  },
  methods: {
    show(item) {
      this.showModal = true;
      this.modalProps = {
        item
      };
    }
  },
  data() {
    return {
      showModal: false,
      modalProps: {}
    };
  }
};
</script>
