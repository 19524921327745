import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import util from "./util"

const fire = {
    init() {
        if (!firebase.apps.length) {
            const conf = {
                apiKey: process.env.VUE_APP_apiKey,
                authDomain: process.env.VUE_APP_authDomain,
                databaseURL: process.env.VUE_APP_databaseURL,
                projectId: process.env.VUE_APP_projectId,
                storageBucket: process.env.VUE_APP_storageBucket,
                messagingSenderId: process.env.VUE_APP_messagingSenderId,
            };
            firebase.initializeApp(conf)
        }
    },
    get base() {
        fire.init()
        return firebase
    },
    get storage() {
        fire.init()
        return {
            uploadImage: async (file, path = "none", id = Date.now()) => {
                const fileRef = firebase.storage().ref(`images/${path}/${id}/${Date.now()}_${file.name}`)
                await fileRef.putString(file.data, 'data_url')
                fileRef.updateMetadata({ cacheControl: 'public,max-age=14400000' })
                const url = await fileRef.getDownloadURL()
                return url
            },
            uploadFile: async (file, path = "none", id = Date.now()) => {
                const fileRef = firebase.storage().ref(`files/${path}/${id}/${file.name}`)
                await fileRef.putString(file.data, 'data_url')
                fileRef.updateMetadata({
                    cacheControl: 'public,max-age=14400000',
                    //contentDisposition: `attachment;  filename='${file.name}'; filename*=UTF-8''${encodeURIComponent(file.name)}`
                })
                const url = await fileRef.getDownloadURL()
                return url
            }
        }
    },
    async userData() {
        const db = this.base.firestore();

        const ref = await db.collection("users").doc(this.base.auth().currentUser.uid).get()

        return ref.exists ? ref.data() : {};
    },
    Contents: class {
        //collection
        constructor(collectionName, usr) {
            fire.init()
            this.loginUser = usr;
            const db = firebase.firestore();
            this.col = db.collection("contents").doc("root").collection(collectionName)
            this.collectionName = collectionName
            this.observer;
            this.addEvent = () => { }
            this.modifyEvent = () => { }
            this.removeEvent = () => { }
        }
        async _uploadFile(file, ref) {
            return await fire.storage.uploadFile(file, this.collectionName, ref.id)
        }
        async _uploadImage(file, ref) {
            return await fire.storage.uploadImage(file, this.collectionName, ref.id)
        }
        appendDoc() {
            const ref = this.col.doc();
            const item = {
                isEmpty: true,
                $isChange: false
            };
            //updateという名のInsert
            item.$update = async (data) => {
                const meta = { ...item._meta };
                meta.created_at = meta.updated_at = firebase.firestore.FieldValue.serverTimestamp()
                meta.create_user_id = meta.update_user_id = this.loginUser.uid;
                meta.create_user_name = meta.update_user_name = this.loginUser.displayName;
                data._meta = meta;
                await ref.set(data)
            }
            item.$uploadImage = async (file) => {
                return await this._uploadImage(file, ref)
            }
            item.$uploadFile = async (file) => {
                return await this._uploadFile(file, ref)
            }

            return item;
        }
        _extendDoc(doc) {
            const item = doc.data()
            const ref = doc.ref;
            item.$isChange = false;
            // metaの互換処理
            if (item._meta) {
                item.updated_at = item._meta.updated_at;
            } else {
                item._meta = {
                    updated_at: item.updated_at,
                    update_user_id: item.update_user_id,
                    update_user_name: item.update_user_name
                }
            }
            item.updated_at
            item.$update = async (data) => {
                const meta = { ...item._meta };
                meta.updated_at = firebase.firestore.FieldValue.serverTimestamp()
                meta.update_user_id = this.loginUser.uid;
                meta.update_user_name = this.loginUser.displayName;
                data._meta = meta;
                await ref.update(data)
            }
            //git管理予定のため物理削除
            item.$delete = async () => {
                await ref.delete()
                //TODO:storage の削除・・・
            }
            item.$uploadImage = async (file) => {
                return await this._uploadImage(file, ref)
            }
            item.$uploadFile = async (file) => {
                return await this._uploadFile(file, ref)
            }
            item.$getUpdated = (format) => {
                return util.formatDate(item._meta.updated_at.toDate(), format)
            }
            //内部用_doc定義
            item._doc = doc
            return item;
        }
        init() {
            //初回ロード用Promise
            return new Promise((ok, ng) => {
                //SnapShotで初回に全件（where以外）がaddとして取得される 
                this.observer = this.col.onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const doc = change.doc
                        if (doc.metadata.hasPendingWrites) {
                            //localの変更はいったんスキップ
                            return
                        }
                        const item = this._extendDoc(doc)
                        //debugger; // eslint-disable-line
                        if (change.type === "added") {
                            //並び替えはVIEｗでやる
                            this.addEvent(item)
                        }
                        if (change.type === "modified") {
                            this.modifyEvent(item)

                        }
                        if (change.type === "removed") {
                            this.removeEvent(item)
                        }
                    });
                    //debugger; // eslint-disable-line
                    ok()
                }, (e => ng(e)))
            })
        }
        destroy() {
            this.observer()
            Object.keys(this.event).map(k => this.event[k] === [])
        }
    }
}
export default fire