<template>
  <div>
    <Loading v-bind="loadingObject" />
    <div class="tile is-ancestor">
      <div class="tile is-vertical">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification is-success">
              <div class="box">
                <p class="title">会員情報</p>
                <p
                  class="subtitle"
                  style="color:red"
                  v-if="currentUser && !currentUser.displayName"
                >お名前を入力して、更新ボタンを押してください。</p>
                <p class="subtitle" v-else>更新ボタンから会員情報を変更できます。</p>
                <div class="field">
                  <label class="label">電話番号</label>
                  <div class="control">{{phoneNumber}}</div>
                </div>
                <div class="field">
                  <label class="label">お名前</label>
                  <div class="control">
                    <input
                      class="input"
                      type="text"
                      v-model="displayName"
                      placeholder="お名前を入力してください"
                    />
                  </div>
                </div>
                <div class="field is-hidden">
                  <label class="label">メールアドレス</label>
                  <div class="control">
                    <input class="input" type="text" v-model="email" placeholder="メールアドレスを入力してください" />
                  </div>
                </div>

                <div class="field">
                  <label class="label">権限</label>
                  <div class="control">
                    <div class="field is-grouped is-grouped-multiline">
                      <p class="control" v-for="(rule,index) in rules" :key="index">
                        <button class="button" :class="ruleStyle(rule)">
                          <span>{{rule.name}}</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <button class="button is-primary" @click="updateUserData">更新</button>
                  </div>
                </div>
                <div class="field">
                  <div class="control">
                    <label class="label">ログアウトする場合は以下のボタンを押してください。</label>
                    <button class="button is-danger" @click="signOut">ログアウト</button>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.isLineThrough {
  text-decoration: line-through;
}
</style>
<script>
import Loading from "./elements/loading.vue";
import fire from "../fire.js";
import config from "../config.json";

const firebase = fire.base;
export default {
  name: "OnlyMember",
  props: {},
  components: { Loading },
  async mounted() {
    if (this.currentUser) {
      //自前dataに設定
      this.phoneNumber = this.currentUser.phoneNumber;
      this.displayName = this.currentUser.displayName;
      this.email = this.currentUser.email;
    } else {
      //router設定でauth必須のはず
    }
  },
  methods: {
    async updateUserData() {
      this.loadingObject.active = true;
      try {
        await this.currentUser.updateProfile({
          displayName: this.displayName
        });
        const uid = this.currentUser.uid;
        //TODO:なんでこんなところでアクセスしてるんだ・・・
        const doc = fire.base
          .firestore()
          .collection("users")
          .doc(uid);
        await doc.set({ name: this.displayName }, { merge: true });
      } catch (e) {
        alert("更新処理に失敗しました。");
        console.log(e); // eslint-disable-line
      }
      this.loadingObject.active = false;
    },
    async signOut() {
      this.loadingObject.active = true;
      try {
        await firebase.auth().signOut();
        this.$router.push("/");
      } catch (e) {
        alert("サインアウトに失敗しました。");
        console.log(e); // eslint-disable-line
      }
      this.loadingObject.active = false;
    },
    hasRule(targetRule, userRule) {
      //ユーザ情報にキーがあるかつ、ルール定義がtrueの項目のみ全て一致していること。
      return (
        userRule[targetRule.key] &&
        Object.keys(targetRule.val)
          .filter(kk => targetRule.val[kk])
          .every(kk => userRule[targetRule.key][kk] === targetRule.val[kk])
      );
    },
    ruleStyle(targetRule) {
      if (this.hasRule(targetRule, this.currentUserRule)) {
        return "is-active";
      } else {
        return "isLineThrough";
      }
    }
  },
  inject: ["providedState"],
  computed: {
    currentUser() {
      return this.providedState.currentUser;
    },
    currentUserRule() {
      return this.providedState.currentData.rule || {};
    }
  },
  data() {
    return {
      loadingObject: {
        active: false,
        title: "処理中です。しばらくお待ちください。"
      },
      displayName: "",
      phoneNumber: "",
      email: "",
      rules: config.rules
    };
  }
};
</script>
