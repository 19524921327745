<template>
  <div>
    <div class="swiper-slide" ref="preloader">
      <div class="swiper-lazy-preloader"></div>
    </div>

    <swiper :options="swiperOption" @ready="ready" ref="mainSlide">
      <swiper-slide v-for="item in swiperSlides" :key="item.key">
        <img title width="100%" class="swiper-lazy" :data-src="item.src" />
        <div class="swiper-lazy-preloader"></div>
        <div class="abtitle">
          <a :href="item.srcOrg" target="_blank">{{item.title}}</a>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<style lang="scss">
//@import "swiper/src/swiper.scss";
</style>
<style>
/*@import "swiper/dist/css/swiper.css";*/
.swiper-container {
  width: 100%;
  max-width: 800px;
  /*height: 100%;*/
  padding-bottom: 20px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  min-height: 100px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide .abtitle {
  position: absolute;
  bottom: -25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
//import slideData from "../assets/topSilder.json";

export default {
  name: "WideSlider",
  components: {
    swiper,
    swiperSlide
  },
  created() {
    this.items
      .filter(e => e.display === "")
      .forEach(e => {
        e.title = e.title || "🔍";
        //エスケープの必要はないが巻き込まれてエスケープされたサムネ名を元に戻す
        e.srcOrg = e.src.split("%40slider%40.jpg").join("")
        this.swiperSlides.push(e);
      });
  },
  props: {
    //msg: String
  },
  inject: ["providedState"],
  computed: {
    swiper() {
      return this.$refs.mainSlide.swiper;
    },
    items() {
      if (this.watchRoot && this.watchRoot[0]) {
        return this.watchRoot[0].items;
      }
      return [];
    }
  },
  methods: {
    stopLoop() {
      this.swiper.autoplay.stop();
    },
    ready() {
      //debugger; // eslint-disable-line
      this.$refs.preloader.style.display = "none";
      //this.swiper.autoplay.start();
    }
  },
  watch: {
    watchRoot: {
      // eslint-disable-next-line
      handler: function(val, oldVal) {
        //console.log("watch 1", "newval: ", val, "   oldVal:", oldVal); // eslint-disable-line
        //debugger; // eslint-disable-line
        this.$emit("recreate");
      }
    }
  },
  data() {
    return {
      swiperSlides: [],
      watchRoot: this.providedState.contents["Slider"],
      swiperOption: {
        preloadImages: false,
        lazy: {
          loadPrevNext: true
        },
        loop: true,
        slidesPerView: 1,
        autoplay: {
          delay: 5000
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      }
    };
  }
};
</script>
