<template>
  <div class="modal" :class="{'is-active':true}">
    <div class="modal-background" @click="close"></div>
    <div class="modal-content" style="width: 100%;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{item.title}}</p>
        <button class="delete" aria-label="close" @click="close"></button>
      </header>
      <section v-if="!showDetail" class="modal-card-body">
        <article class="media is-block-mobile">
          <div class="media-left" v-if="item.thumbnailSrc">
            <figure class="image is-128x128">
              <img :src="item.thumbnailSrc" alt="Image" />
            </figure>
          </div>
          <div class="media-content">
            <div class="content">
              <p v-html="item.body.html"></p>
              <p v-if="item.detailBody && item.detailBody.html">
                <a @click="showDetail=true">続きを読む</a>
              </p>
            </div>
          </div>
        </article>
      </section>
      <section v-else class="modal-card-body">
        <div class="content">
          <p v-html="item.detailBody.html"></p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal-item",
  props: {
    item:Object
  },
  methods: {
    close(){
      if(this.showDetail){
        this.showDetail=false;
      }else{
        this.$emit('close');
      }
    }
  },
  data() {
    return {
      showDetail:false
    };
  }
};
</script>
