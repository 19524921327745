<template>
  <div ref="rootElement">
    <Menu></Menu>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
import store from "./store.js";
import Menu from "./components/Menu.vue";

export default {
  name: "app",
  components: { Menu },
  mounted() {
    this.$refs.rootElement.addEventListener("click",e=>{
      const target = e.target;
      if(target.tagName === "IMG" && target.src.endsWith("@thumbnail@.jpg")){
        window.open(target.src.substr(0,target.src.length-15));
      }
    },false);
  },
  data: function() {
    return {
      sharedState: store.docs
    };
  },
  provide() {
    return {
      providedState: this.sharedState
    };
  }
};
</script>

<style lang="scss">
@import "bulma/bulma.sass";
img.column.box{
  object-fit:scale-down;
}
.modal-card-title{
  max-width:90vw;
}
</style>