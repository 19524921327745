import Vue from 'vue'
import App from './App.vue'
import Top from './components/Top.vue'
import Login from "./components/Login.vue";
import OnlyMember from "./components/会員専用.vue"
import GameResults from "./components/大会結果.vue"
import Download from "./components/ダウンロード.vue"
import Topics from "./components/トピックス.vue"
import EditSlider from "./components/スライダー編集.vue"
import Special from "./components/特集コーナー.vue"
import Notice from "./components/お知らせ.vue"
import SkillUp from "./components/スキルアップ.vue"
import AdminPage from "./components/管理者用.vue"
import AuthorityManagement from "./components/権限管理.vue"
import ReleaseManagement from "./components/リリース.vue"
import DevelopIssue from "./components/開発要望.vue"
import OrganizationInfo from "./components/組織案内.vue"
import SlideMessageEdit from "./components/elements/slide-message-edit.vue"


import Router from 'vue-router'
import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css'

// 使用するカテゴリーのファイルを import

import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css'


Vue.use(Router)

import store from './store.js'
import fire from './fire.js'
//debugger; // eslint-disable-line

Vue.config.productionTip = false

const router = new Router({
  scrollBehavior(to, from, savedPosition) {// eslint-disable-line
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'top',
      component: Top
    },
    {
      path: '/会員専用',
      name: '会員専用',
      component: OnlyMember,
      meta: { requiresAuth: true }
    },
    {
      path: '/ダウンロード',
      name: 'ダウンロード',
      component: Download
    },
    {
      path: '/大会結果',
      name: '大会結果',
      component: GameResults
    },
    {
      path: '/トピックス',
      name: 'トピックス',
      component: Topics
    },
    {
      path: '/指導・普及',
      name: '指導・普及',
      component: SkillUp
    },
    {
      path: '/組織案内',
      name: '組織案内',
      component: OrganizationInfo
    },
    {
      path: '/お知らせ',
      name: 'お知らせ',
      component: Notice
    },
    {
      path: '/特集コーナー',
      name: '特集コーナー',
      component: Special
    },
    {
      path: '/スライダー編集',
      name: 'スライダー編集',
      component: EditSlider,
      meta: { requiresAuth: true }
    },
    {
      path: '/スライドメッセージ編集',
      name: 'スライドメッセージ編集',
      component: SlideMessageEdit,
      meta: { requiresAuth: true }
    },
    {
      path: '/権限管理',
      name: '権限管理',
      component: AuthorityManagement,
      meta: { requiresAuth: true }
    },
    {
      path: '/リリース管理',
      name: 'リリース管理',
      component: ReleaseManagement,
      meta: { requiresAuth: true }
    },
    {
      path: '/開発要望',
      name: '開発要望',
      component: DevelopIssue,
      meta: { requiresAuth: true }
    },
    {
      path: '/管理者用',
      name: '管理者用',
      component: AdminPage,
      meta: { requiresAuth: true }
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login,
      props: {
        msg: "このページはログインが必要です"
      }
    }
  ]
})
router.beforeEach((to, from, next) => {
  //debugger; // eslint-disable-line
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  //debugger; // eslint-disable-line
  fire.base.auth().onAuthStateChanged(async (user) => {
    //FIXME:いったんここに入れてみる。
    //debugger; // eslint-disable-line
    await store.docs.init(user);
    if (user) {
      next()
    } else {
      if (requiresAuth) {
        next({
          path: '/Login',
          query: { redirect: to.fullPath }
        })
      } else {
        next()
      }
    }
  })

})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
