<template>
  <div>
    <modal v-bind="modalProps" v-if="showModal" @close="showModal = false" />
    <div class="modal" :class="{ 'is-active': !showModal }">
      <div class="modal-background" @click="$emit('close')"></div>
      <div class="modal-content" style="width: 100%">
        <header class="modal-card-head">
          <p class="modal-card-title">▼{{ title }}</p>
          <button
            class="delete"
            aria-label="close"
            @click="$emit('close')"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="content">
            <div
              v-for="(i, index) in items"
              :key="index"
              class="list is-hoverable is-fullwidth"
            >
              <a
                class="list-item"
                style="text-align: left; justify-content: left"
                @click="show(i)"
                >{{ i.title }}</a
              >
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import modal from "./modal-item.vue";
export default {
  name: "categoryChild",
  components: {
    modal,
  },
  props: {
    items: Array,
    title: String,
  },
  computed: {},
  methods: {
    show(item) {
      this.showModal = true;
      this.modalProps = {
        item
      };
    },
  },
  data() {
    return {
      showModal: false,
      modalProps: {},
    };
  },
};
</script>
