<template>
  <div class="modal" :class="{'is-active':true}">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content" style="width: 100%;">
      <header class="modal-card-head">
        <p class="modal-card-title">{{title}}</p>
        <button class="delete" aria-label="close" @click="$emit('close')"></button>
      </header>
      <section class="modal-card-body">
        <div class="content">
          <p v-html="html"></p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",
  props: {
    title: String,
    html: String
  },
  data() {
    return {};
  }
};
</script>
