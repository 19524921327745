<template>
  <div>
    <Loading v-bind="loadingObject" />
    <div class="tile is-ancestor">
      <div class="tile is-vertical">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification is-success">
              <p class="title">権限を設定</p>
              <button
                class="button is-danger"
                @click="update()"
                style="position:absolute;top:5px;right:5px"
                v-if="canUpdate"
              >更新</button>
              <div class="box" v-for="(user,userIndex) in users" :key="user.key">
                <div class="field">
                  <label class="label">名前</label>
                  <div class="control">{{user.name}}</div>
                </div>
                <div class="field">
                  <label class="label">権限</label>
                  <div class="control">
                    <div class="field is-grouped is-grouped-multiline">
                      <p class="control" v-for="(rule,ruleIndex) in rules" :key="ruleIndex">
                        <button
                          class="button"
                          :class="ruleStyle(rule,user.rule,usersOriginal[userIndex].rule)"
                          @click="canUpdate && toggle(rule,user.rule,usersOriginal[userIndex].rule)"
                        >
                          <span>{{rule.name}}</span>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.isLineThrough {
  text-decoration: line-through;
}
</style>
<script>
import Loading from "./elements/loading.vue";
import fire from "../fire.js";
import config from "../config.json";
export default {
  name: "AuthorityManagement",
  props: {},
  components: { Loading },
  async mounted() {
    try {
      //debugger; // eslint-disable-line
      await this.refreshUserList();
    } catch (e) {
      alert("権限がありません。");
    }
  },
  methods: {
    toggle(targetRule, userRule, userRuleOrg) {
      //debugger; // eslint-disable-line
      const hasauth = this.hasRule(targetRule, userRule);
      const hasauthOrg = this.hasRule(targetRule, userRuleOrg);
      if (hasauth) {
        if (hasauthOrg) {
          this.$set(userRule, targetRule.key, {});
        } else {
          this.$set(userRule, targetRule.key, {});
        }
      } else {
        if (hasauthOrg) {
          this.$set(userRule, targetRule.key, targetRule.val);
        } else {
          this.$set(userRule, targetRule.key, targetRule.val);
        }
      }
    },
    async update() {
      //エラ〜メッセージ配列。
      const msg = [];
      for (let i in this.users) {
        const user = this.users[i];
        let toggle = false;
        for (let k in config.rules) {
          const targetRule = config.rules[k];
          const userRule = user.rule;
          const userRuleOrg = this.usersOriginal[i].rule;
          const hasauth = this.hasRule(targetRule, userRule);
          const hasauthOrg = this.hasRule(targetRule, userRuleOrg);

          if (hasauth !== hasauthOrg) {
            toggle = true;
          }
        }
        if (toggle) {
          try {
            const doc = await fire.base
              .firestore()
              .collection("users")
              .doc(user.key);
            await doc.set({ rule: user.rule }, { merge: true });
          } catch (e) {
            msg.push(`${user.name}の権限更新に失敗しました。`);
            console.log(e); // eslint-disable-line
          }
        }
      }
      if (msg.length > 0) {
        alert(msg.join("\n"));
      }
      await this.refreshUserList();
    },
    async refreshUserList() {
      const doc = await fire.base
        .firestore()
        .collection("users")
        .limit(100)
        .get();
      this.users = [];
      this.usersOriginal = [];
      for (let i in doc.docs) {
        const d = doc.docs[i];
        const data = d.data();
        data.key = d.id;
        data.rule = data.rule || {};
        this.users.push(data);
        this.usersOriginal.push(JSON.parse(JSON.stringify(data)));
      }
    },
    hasRule(targetRule, userRule) {
      //ユーザ情報にキーがあるかつ、ルール定義がtrueの項目のみ全て一致していること。
      return (
        userRule[targetRule.key] &&
        Object.keys(targetRule.val)
          .filter(kk => targetRule.val[kk])
          .every(kk => userRule[targetRule.key][kk] === targetRule.val[kk])
      );
    },
    ruleStyle(targetRule, userRule, userRuleOrg) {
      const ret = [];
      const hasauth = this.hasRule(targetRule, userRule);
      const hasauthOrg = this.hasRule(targetRule, userRuleOrg);

      if (hasauth) {
        ret.push("is-active");
      } else {
        ret.push("isLineThrough");
      }
      if (hasauthOrg != hasauth) {
        ret.push("is-warning");
      }
      return ret.join(" ");
    }
  },
  inject: ["providedState"],
  computed: {
    currentUser() {
      return this.providedState.currentUser;
    },
    currentUserRule() {
      return this.providedState.currentData.rule || {};
    },
    canUpdate() {
      return (
        this.currentUserRule._users &&
        this.currentUserRule._users.write === true
      );
    }
  },
  data() {
    return {
      loadingObject: {
        active: false,
        title: "処理中です。しばらくお待ちください。"
      },
      displayName: "",
      phoneNumber: "",
      users: [],
      usersOriginal: [],
      rules: config.rules
    };
  }
};
</script>
