<template>
  <div>
    <Loading v-bind="loadingObject" />
    <div class="tile is-ancestor">
      <div class="tile is-vertical">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification is-success">
              <p class="title">リリース管理</p>
              <div class="field">
                <button
                  class="button is-warning"
                  @click="exec('release_channel')"
                  v-if="canExec"
                >確認用環境へリリース</button>
                <button
                  class="button is-danger"
                  @click="exec('release_production')"
                  v-if="canExec"
                >Production環境へリリース</button>
              </div>

              <div class="box" v-for="(item,itemIndex) in releaseItems" :key="item.key">
                <div class="field">
                  <label class="label">No.{{itemIndex+1}} リリース日時:{{dateStr(item.createdAt)}}</label>
                  <div v-if="item.stage==='channel'">
                    <label class="label">確認用環境</label>
                    <a :href="item.details_url" class="button is-link" target="_blank">結果確認</a>
                  </div>
                  <div v-if="item.stage==='production'">
                    <label class="label">Production環境</label>
                    <!-- FIXME:暫定で固定設定する。 -->
                    <a href="https://osaka-bound.com/" class="button is-danger" target="_blank">結果確認</a>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loading from "./elements/loading.vue";
import fire from "../fire.js";
const release = async docKey => {
  //毎回サーバからとってくる
  const doc = await fire.base
    .firestore()
    .collection("config")
    .doc(docKey)
    .get();
  const data = doc.data();
  //エラ〜メッセージ配列。
  const ret = await fetch(data.githubActionUrl, {
    method: "post",
    headers: new Headers({
      Authorization: "token " + data.githubToken,
      Accept: "application/vnd.github.everest-preview+json"
    }),
    body: JSON.stringify(data.body)
  });
  if (ret.status != 204) {
    throw new Error("Http status = " + ret.status);
  }

  await new Promise((ok, ng) => {
    const observer = fire.base
      .firestore()
      .collection("release")
      .where("createdAt", ">", new Date())
      .onSnapshot(
        snapshot => {
          snapshot.docChanges().forEach(change => {
            if (change.type === "added") {
              ok();
              observer();
            }
          });
        },
        e => ng(e)
      );
  });
};
export default {
  name: "ReleaseManagement",
  props: {},
  components: { Loading },
  async mounted() {
    try {
      //debugger; // eslint-disable-line
      await this.refreshList();
    } catch (e) {
      alert("権限がありません。");
    }
  },
  methods: {
    dateStr(firestoreTimestamp) {
      let date;
      if (firestoreTimestamp.toDate) {
        date = firestoreTimestamp.toDate();
      }
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
    async exec(docKey) {
      let stage;
      if (docKey === "release_channel") {
        stage = "確認用環境";
      } else {
        stage = "Production環境";
      }
      if (!confirm(stage + "にリリースを行います。よろしいですか？")) {
        return;
      }
      this.loadingObject.active = true;
      try {
        await release(docKey);
        await this.refreshList();
        alert("リリースが成功しました。\n結果確認ボタンから確認してください。");
      } catch (e) {
        alert("リリースに失敗しました。\n" + e.message);
        console.error(e); // eslint-disable-line
      }
      this.loadingObject.active = false;
    },
    async refreshList() {
      const doc = await fire.base
        .firestore()
        .collection("release")
        .orderBy("createdAt", "desc")
        .limit(10)
        .get();
      this.releaseItems = [];
      this.releaseItemsOriginal = [];
      const last = new Date();
      last.setDate(last.getDate() - 7);
      for (let i in doc.docs) {
        const d = doc.docs[i];
        const data = d.data();
        data.key = d.id;

        if(data.createdAt.toDate().getTime() > last.getTime()){
          this.releaseItems.push(data);
        }
      }
    }
  },
  inject: ["providedState"],
  computed: {
    currentitem() {
      return this.providedState.currentitem;
    },
    currentitemRule() {
      return this.providedState.currentData.rule || {};
    },
    canExec() {
      return (
        this.currentitemRule._release &&
        this.currentitemRule._release.write === true
      );
    }
  },
  data() {
    return {
      loadingObject: {
        active: false,
        title: "処理中です。しばらくお待ちください。"
      },
      releaseItems: []
    };
  }
};
</script>
