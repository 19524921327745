<template>
  <div style="width: 100%;display: table;">
    <div style="display: table-cell;text-align: left;">
      <img src="/static/favicon_white.jpeg" class="img" style="max-width: 65px;"/>
    </div>
    <div style="display: table-cell;text-align: center;vertical-align: middle;">
      <img src="/static/topLogo.png" class="img" />
    </div>
    <div style="display: table-cell;text-align: right;vertical-align: middle;">
      <img src="/static/logo20210425.png" class="img" />
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
  props: {
    //msg: String
  },
  data() {
    return {};
  }
};
</script>
