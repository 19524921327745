<template>
  <div>
    <Loading v-bind="loadingObject" />
    <Modal v-bind="modalProps" v-if="showModal" @close="showModal = false" />
    <div class="modal" v-if="showEditor" :class="{'is-active':true}">
      <div class="modal-background" @click="showEditor = false"></div>
      <contents-editor
        class="modal-content"
        style="width: 100%;"
        :target="editTarget"
        contents-label="トピックスの記事登録"
        @close="showEditor = false"
      />
    </div>
    <div class="tile is-ancestor">
      <div class="tile is-vertical">
        <div class="tile">
          <div class="tile is-parent is-vertical">
            <article class="tile is-child notification is-success">
              <p class="title">トピックス</p>
              <button
                class="button is-danger"
                v-if="hasAuth"
                @click="create()"
                style="position:absolute;top:5px;right:5px"
              >新規作成</button>
              <div
                class="box"
                style="position:relative"
                v-for="(t,index) in sortedItems"
                :key="index"
              >
                <article class="media is-block-mobile">
                  <button
                    class="button is-danger"
                    v-if="hasAuth"
                    @click="edit(t)"
                    style="position:absolute;top:5px;right:5px"
                  >編集</button>
                  <div class="media-left" v-if="t.thumbnailSrc">
                    <figure class="image is-128x128">
                      <img :src="t.thumbnailSrc" alt="Image" />
                    </figure>
                  </div>
                  <div class="media-content">
                    <div class="content">
                      <p>
                        <strong>{{t.title}}</strong>
                      </p>
                      <p v-html="t.body.html"></p>
                      <p v-if="t.detailBody && t.detailBody.html">
                          <a @click="show(t)">続きを読む</a>
                      </p>
                    </div>
                  </div>
                </article>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from "./elements/modal.vue";
import Loading from "./elements/loading.vue";
import ContentsEditor from "./ContentsEditor.vue";
export default {
  name: "Topics",
  components: { Modal, Loading, ContentsEditor },
  methods: {
    edit(target) {
      this.showEditor = true;
      this.editTarget = target;
    },
    create() {
      this.showEditor = true;
      this.editTarget = this.items.$contents.appendDoc();
    },
    show(item) {
      this.showModal = true;
      this.modalProps = {
        title: item.title,
        html: item.detailBody.html,
      };
    },
  },
  inject: ["providedState"],
  computed: {
    sortedItems() {
      return this.items
        .slice()
        .sort((a, b) => (b.updated_at > a.updated_at ? 1 : -1));
    },
    items() {
      return this.providedState.contents["Topics"];
    },
    hasAuth() {
      return this.items && this.items.$hasAuth();
    }
  },
  data() {
    return {
      showEditor: false,
      editTarget: null,
      loadingObject: {
        active: false,
        title: "処理中です。しばらくお待ちください。"
      },
      showModal: false,
      modalProps: {},
    };
  }
};
</script>
